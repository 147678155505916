import "./App.css";
import ShortContainer from "./components/ShortContainer";

function App() {
  return (
    <div>
      <nav className="navbar">
        <div className="nav">
          <div className="logo-container">
            <img src="/pie-chart.svg" className="logo" alt="" />
            Happy Reels
          </div>
        </div>
      </nav>
      <main className="main">
        <ShortContainer />
      </main>
    </div>
  );
}

export default App;
