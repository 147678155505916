import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Short from "./Short";

function ShortContainer() {
  const shortContainerRef = useRef();
  const [shorts, setShorts] = useState([]);

  useEffect(() => {
    // Fetch reels data from API
    const fetchData = async () => {
      try {
        const response = await axios.get("https://happyreels-backend.desihentai.com/api/reels/fetchRandom");
        setShorts(response.data); // Store fetched data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Run only on component mount

  return (
    <>
      <section ref={shortContainerRef} className="short-container">
        {shorts.map((short, index) => (
          <Short
            key={index}
            shortContainerRef={shortContainerRef}
            short={short}
          />
        ))}
      </section>

      <div className="navigation-container">
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop - window.innerHeight
            );
          }}
          className="nav-up"
        >
          <ion-icon name="arrow-up-outline"></ion-icon>
        </div>
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop + window.innerHeight
            );
          }}
          className="nav-down"
        >
          <ion-icon name="arrow-down-outline"></ion-icon>
        </div>
      </div>
    </>
  );
}

export default ShortContainer;
